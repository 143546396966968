
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-trivia-component] {

  .btn {
    border-radius: 0.5rem;
  }

  .avatar {
    width: 3rem;
    height: 3rem;
    top: -1rem;
    left: -1rem;
    border-radius: 50%;
    position: absolute;

    .fal {
      line-height: 3rem;
      font-size: 1.5rem;
    }
  }

}
