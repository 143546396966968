
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  color: $dark !important;
  
  .event-date-text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }

  .small {
    font-weight: 200;
  }

  .tile-title {
    .small-text & {
      font-size: 1.125rem;
    }
  }

  .description {
    font-size: 1.15rem;
    font-weight: 400;

    .small-text & {
      font-size: .8125rem;
    }

    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
