
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-advanced-component] {
  .embed-responsive {
    height: 100%;
  }

  .embed_notification {
    opacity: 0.7;
    position: absolute;
    right: 2rem;
    bottom: 3.25rem;
    z-index: 1050;
    
    .btn { 

      i {
        font-size: 1rem;
        margin-top: 1px;
      }
    }
  } 

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2rem;
  }

  .embed-fullscreen__controls {

    i {
      font-size: 1.5rem;
    }

    &.bg-gray-1000 {
      background-color: $gray-1000;
    }
  }
  
  .unsupported-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1090;
    background-color: $black;
  }

  .embed_content {
    width: 100vw;
  }

  @include media-breakpoint-down(md) {

    .embed_notification {
      left: 1rem;
      top: 1rem;
      bottom: auto;
      right: auto;

      &.embed_notification--ipad {
        top: 5rem !important;
      }
    } 
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: 100vh;
      width: calc((16 / 9) * 100vh) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
}
