
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keyopints-tile-component] {

  .keypoint-title {
    font-size: 1.875rem;
    font-weight: normal;
  }

  @include media-breakpoint-up(md) {

    .keypoint-text {
      max-width: 80%;
    }
  }

}

