
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-trivia-carousel-component] {

  .trivia-carousel-item {
    min-height: calc(75vw * 0.5625 - 6rem);

    @include media-breakpoint-down(sm) {
      min-height: calc(100vh - #{$header-height-sm} - 6rem);
    }
  }

  .trivia-carousel__feedback {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    pointer-events: none;
    z-index: 1020;

    > div {
      color: $white;
      padding: 0.5rem 2rem;
      border-radius: 20px;

      &.bg-success {
        color: $dark !important;
      }
    }
  }

  .answer {
    font-size: 0.875rem;
    line-height: 1.25;
    cursor: pointer;
    position: relative;
    border: solid 1px #808080;
    overflow: hidden;
    transition: all $animation-time-fast linear;
    
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  .answer:not(.not-answerable):hover {
    background-color: $black;
    color: $white;
  }

  .choose {
    box-shadow: inset 0px 0px 0px 2px $black;
  }


  @include media-breakpoint-down(xs) {
    .answer {
      font-size: 0.9rem;
    }
  }

  .trivia-carousel__navigation {
    top: 0;
    left: 0;
    z-index: 1;
    height: 60px;
  }
  
  .trivia-carousel__navigation-icon {
    font-size: 2.5rem;
    color: $dark;
  }

  .trivia-carousel-item ::v-deep [course-countdown-component] {
    .course-countdown-undo {
      color: $black;
    }

    .svg-container {

      .countdown-number {
        color: $black;
      }
    }
  }

}
