
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-gamebook-component] {

  .gamebook-container {
    position: relative;
    width: 100vw;
    height: calc((9 / 16) * 100vw);
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-aspect-ratio: 16/9) {
    .gamebook-container {
      height: 100vh;
      width: calc((16 / 9) * 100vh);
    }
  }
}
