
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[buttons-component] {
  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
}
