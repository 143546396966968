
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-fullscreen-component] {
  position: relative;

  .is-single-slide .flickity-page-dots {
    display: none;
  }

  .flickity-carousel {
    position: relative;
  }

  .next-container {
		font-size: 1.5rem;
		position: absolute;
		bottom: 0;
		color: $white;
	}

  .flickity-page-dots {
    bottom: 4rem;

    .dot {
      background-color: $white;
      border: 1px solid $white;
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }
  }
}
