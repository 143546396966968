
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-survey-carousel-item-component] {

  .btn {
    i {
      font-size: 1.55rem;
    }
  }

  .form-label {
    font-size: 1.25rem;
  }

}
