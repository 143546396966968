
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-spacer-component] {
  @include media-breakpoint-up(sm) {
    &.mb-n6 {
      margin-bottom: -5rem;
    }
  }
}
