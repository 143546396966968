
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[user-password-editing-form-component] {
  .form-group {
    margin-bottom: 2rem;
  }
}
