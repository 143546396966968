
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.live-event-modal {
  .fa-times {
    top: 0;
    right: 0;
  }
}

.alert-modal__content {
  width: 100%;
  max-width: 30rem;
  overflow-y: scroll;
  border-radius: 0;
  background-color: var(--theme-background);
  color: var(--theme-color);
  max-height: calc(100% - 3rem);
}
