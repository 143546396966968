
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-share-component] {
  color: $white;

  .share-icon {
    stroke: $white;
    width: 1.5rem;
    height: 1.5rem;
  }
}
