
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


[course-countdown-component] {

  .course-countdown-undo {
    color: $white;
  }

  .svg-container {

    .countdown {
      position: relative;
      height: 70px;
      width: 70px;
      text-align: center;
    }

    .countdown-number {
      color: $white;
      font-size: 1rem;
      font-weight: bold;
      z-index: 1;
    }

    .svg-container svg circle {
      stroke: $white;
      transition: all $animation-time-slow linear;
      stroke-dashoffset: 352; // svg circle circumference
      stroke-dasharray: 352;
    }

    .svg-container.fill svg circle {
      stroke: $black;
      stroke-dashoffset: 705 // svg circle circumference * 2;
    }
  }
}  
