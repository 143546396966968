
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-event-component] {
  font-family: $font-family-headings;
  font-size: $h5-font-size;

  .live-icon {
    width: 19px;
    height: 19px;
  }

  .title-icon {
    width: 1.5rem;
    height: 1.5rem;

    &.ondemand {
      stroke: var(--gray-600);
      opacity: .5;
    }
  }

  a {
    color: var(--theme-color) !important;
  }

  .text-section {
    min-height: 13rem;
  }
}
