
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[player-quiz-component] {
  .rounded {
    border-radius: $player-border-radius !important;
  }

  &.quiz {
    display: flex;
    flex-direction: column;

    .quiz-body {
      flex: 1;

      .quiz__logo {
        width: 7rem;
      }

      .quiz__title {
        color: #10111D;
      }

      .quiz__description {
        color: #7B7C83;
      }

      .placeholder {
        &-image {
          width: 9rem;
          height: 9rem;
          color: var(--gray);
          background: var(--gray-light);

          @include media-breakpoint-down(md) {
            width: 6rem;
            height: 6rem;

            i {
              font-size: 2em;
            }
          }

          i {
            opacity: 0.75;
          }
        }

        &-text {
          color: var(--gray);
          text-align: center;
        }
      }

      .flickity-carousel-item {
        width: 100%;

        ::v-deep .question-card {
          background-color: $white !important;

          .answer {
            border: 1px solid var(--player-grey) !important;
          }

          .question-title, .answer {
            opacity: 0;
          }
        }

        ::v-deep [generic-form-component] {
          form {
            padding-bottom: 1rem;

            textarea {
              resize: none;
            }

            .progress {
              display: none;
            }
          }
        }

        ::v-deep [form-feedback-component] {
          .text-success {
            display: none;
          }
        }

        .quiz-result {
          &__dots {
            list-style: none;
            line-height: 1;

            .dot {
              display: inline-block;
              width: .5rem;
              height: .5rem;
              margin: 0 .5rem;
              border-radius: 50%;
              background-color: $player-grey;

              &.correct {
                background-color: $correct-color;
              }

              &.wrong {
                background-color: $wrong-color;
              }
            }
          }
        }
      }

      ::v-deep .flickity-page-dots {
        position: relative;
        bottom: 0;

        .dot {
          pointer-events: none;

          &.correct {
            background-color: $correct-color;
            opacity: 1;
          }

          &.wrong {
            background-color: $wrong-color;
            opacity: 1;
          }
        }
      }
    }
  }
}
