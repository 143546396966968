
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-component] {

  color: var(--theme-color);
  background-color: var(--theme-background);

  .sidebar {
    top: 0;
    left: 0;
    bottom: 0;
    width: 26.5rem;
    max-width: 100%;
    position: fixed;
    overflow-y: auto;
    z-index: $zindex-youtube-popup;
  }

  .show-sidebar {
    left: 1.5rem;
    z-index: 1;
    position: fixed;
    border-radius: 0;
    top: calc(#{$header-height-sm} + 2.5rem);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .course-product__sidebar {
    background-color: rgba($gray-200, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include media-breakpoint-down(sm) {
      
    .sidebar {
      width: 85%;
    }  
  }

}
