
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-advanced-new-component] {

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2rem;
  }
  
  .unsupported-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1090;
    background-color: $black;
  }

  .embed_content {
    width: 100vw;
  }

  .embed_notification {
    position: absolute;
    left: 1rem;
    top: 1rem;
    
    .notification { 
      padding: 0.75rem 1rem;
      background-color: var(--theme-background);
      color: var(--theme-color);
      border-radius: 0;
      border-width: 0;
      &:focus {outline:0;}

      i {
        font-size: 1.25rem;
        margin-top: 1px;
      }
    }
  }

  .timer {
    top: 1.5rem;
    z-index: 1;
    right: 1.5rem;
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    border: 2px solid var(--theme-color);
    background-color: var(--theme-background);

    @include media-breakpoint-down(sm) {
      width: 4rem;
      height: 4rem;
    }
  }

  @include media-breakpoint-down(md) {

    .embed_notification {
      &.embed_notification--ipad {
        top: 5rem !important;
      }
    } 
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: 100vh;
      width: calc((16 / 9) * 100vh) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
}
