
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-streaming-component] {

  background-color: var(--theme-background);
  color: var(--theme-color);

  .live-steaming-sidebar {
    width: 26.5rem;
  }

  .stripes__container .container {
    width: 100% !important;
    max-width: none;
  }
}
