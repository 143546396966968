
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-button-component] {
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: $zindex-fixed;

  color: var(--theme-color);
  background-color: var(--theme-background);
  border-color: var(--theme-border-color) !important;

  .banner-title {
    font-size: 1.375rem;
    font-family: 'Audi Extended';
  }

  @include media-breakpoint-down(sm) {
    a.btn {
      width: 100%;
    }
  }
}
