
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[trivia-component] {
  z-index: $zindex-modal;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  left: 1.5rem;

  .interaction-container {
    border-radius: 15px;
    background-color: #333333;
    color: $white;
    overflow: hidden;
    width: 1100px;
    max-width: 100%;
  }

  .fa-times {
    position: absolute;
    top: 0;
    right: 0;
  }

  .round-time-bar {
    background: var(--theme-color);

    div {
      height: 6px;
      width: 100%;
      transition: width var(--duration) linear;
      background: $primary;

      &.activated {
        width: 0;
      }
    }
  }
}
