
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  position: relative;

  .flickity-viewport {
    margin-bottom: 1rem;
    margin-top: .25rem;
  }

  .flickity-viewport:focus, .flickity-carousel:focus {
    outline: none !important;
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-carousel-item {
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: .75rem; // Prevent shadow overflow

    > div {
      position: relative;
    }
  }

  .view-more-link {
    background-color: $gray-900;
    color: $gray-700;

    & > i {
      transition: transform;
      transition-duration: 3s;
      transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    &:hover > i {
      transform: scale(1.15);
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 60%;
  }

  .stripe-carousel__skin-2 .flickity-carousel-item {
    width: 20%;
  }

  .stripe-carousel__skin-3 .flickity-carousel-item {
    width: calc(100% / 3.15);
  }

  .stripe-carousel__skin-4 .flickity-carousel-item {
    width: 80%;
  }

  .stripe-carousel__skin-4 {
    .flickity-viewport::before, .flickity-viewport::after {
      content: '';
      height: 100%;
      position: absolute;
      width: 6.25rem;
      background-color: #dc3545;
      z-index: 1;
    }

    .flickity-viewport::before {
      background: rgb(0,0,0);
      background: linear-gradient(to left, rgba(256,256,256, 0), $dark);
    }

    .flickity-viewport::after {
      right: 0;
      background: rgb(0,0,0);
      background: linear-gradient(to right, rgba(256,256,256, 0), rgba($dark, .95));
    }
  }

  @include media-breakpoint-down(lg) {

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: calc(100% / 2.15);
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 25%;
    }
  }

  @include media-breakpoint-down(sm) {

    .flickity-carousel-item {
      padding-left: .25rem;
      padding-right: .25rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item, .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 90%;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 75%;
    }

    .stripe-carousel__skin-4 .flickity-carousel-item {
      width: 100%;
    }
  }
}
