
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-survey-carousel-inline-component] {

  .survey__progress {
    height: 10px;
  } 

  [course-survey-carousel-item-component], .survey__progress {
    width: 600px;
    max-width: 100%;
  }
}
