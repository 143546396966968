
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-marketing-consent-component] {
  position: fixed;
  top: 0;
  right: 0;
  width: 750px;
  max-width: 100%;
  z-index: 1050;

  p > a {
    text-decoration: underline !important;
    color: $black !important;
    font-weight: normal;
  }

  p {
    font-size: 1rem;
  }

  form p, form label {
    font-size: 0.85rem;
  }

  .custom-control-label {
    padding-left: 5px;
    padding-top: 2px;
  }

  .custom-control-label::before,
  .custom-control-label::after {
    border-radius: 0;
    width: 1.5rem;
    height: 1.5rem;
    top: 0rem;
  }

  @include media-breakpoint-down(xs) {
    button {
      width: 100%;
    }
  }
}
