
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[text-section-component] {

  .align-btn-group {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .icon {
    max-width: 100%;
    width: 6rem;
  }

  .category {
    font-size: 1.125rem;
    font-family: $font-family-base;
    text-transform: none;
    letter-spacing: normal;
    font-weight: 400;
  }

  [subtitle] {
    font-family: $font-family-base;
    font-size: 1.5rem;
    font-weight: normal;
  }
}
