
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.question {
  border: solid 1px $gray-200;
  min-height: 4rem;
  resize: none;
}
