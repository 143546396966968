
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.fab {
  font-size: 2rem;
}

.border-bottom {
  border-color: #cccccc !important;
}

.footer-section {
  width: calc(100% / 4);
  &.right-links > div {
    width: 12rem;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.section-title {
  font-family: "Audi";
  font-size: 1.25rem;
  font-weight: 400;
}

.section-item {
  font-size: $font-size-xs;
  font-weight: 200;
  font-family: Audi;
  transition: color .5s ease-in-out;

  @include media-breakpoint-up(md) {
    border-bottom: 0 !important;
  }
}

.collapse-toggle {
  transition: all .25s;

  &[aria-expanded=true] {
    transform: rotate(180deg);
  }
}

.user-icon {
  width: 1.5rem;
  height: 1.5rem;
  fill: $black;
}

.light {
  color: $black;
  background-color: $very-light-pink;

  a {
    color: $black;
  }

  .section-item {
    color: $black;

    @include hover {
      color: $black;
    }
  }
}

.dark {
  color: $white;
  background-color: $gray-1000;

  a {
    color: $white;
  }

  .section-item {
    color: $white;

    @include hover {
      color: $very-light-pink;
    }
  }

  .user-icon {
    //stroke: #fff;
    fill: #fff;
  }
}
