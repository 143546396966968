
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-advanced-component] {
  .embed-advanced {
    overflow: hidden;
    background-color: var(--theme-background);

    &--fullscreen {
      background-color: transparent;
      height: 100% !important;

      .video-container {
        position: relative;
      }
    }

    ::v-deep [page-live-event-notice-component] {
      position: absolute;
      bottom: calc(#{$player-control-bar-height} + 1.5rem);
      left: 0;
      right: 0;
      z-index: 1030;
    }

    @include media-breakpoint-down(md) {
      &:not(.embed-advanced--fullscreen) {
        .video-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          ::v-deep [page-live-event-notice-component] {
            position: relative;
            bottom: auto;
            order: 1;
            width: 100%;
          }
        }
      }

      &.embed-advanced--fullscreen {
        ::v-deep [page-live-event-notice-component] {
          position: fixed;
        }
      }
    }
  }

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2rem;
    z-index: 1;
  }

  ::v-deep .embed-fullscreen__controls {
    width: 100%;
    background-color: $dark;

    i {
      font-size: 1.5rem;
    }

    span {
      top: 2px;
      position: relative;
    }
  }

  .embed_notification {
    position: absolute;
    z-index: 1090;
    
    .notification { 
      padding: 0.75rem 1rem;
      background-color: var(--theme-background);
      color: var(--theme-color);
      border-radius: 6px;
      border-width: 0;
      
      &:focus {
        outline:0;
      }

      i {
        font-size: 1.25rem;
        margin-top: 1px;
      }
    }
  } 

  @include media-breakpoint-down(md) {

    .embed_notification {
      &.embed_notification--ipad {
        top: 5rem !important;
      }
    }

    .embed-responsive.small {
      inset: auto 16px 16px auto;
    }
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: var(--inner-height);
      width: Min(calc((var(--inner-height)) * 16 / 9), 100%) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  ::v-deep .fandom-player {
    &.player-container {
      .ratio-9by16 {
        .player-overvideo-items {
          right: auto;
          left: auto;
        }
      }

      .ratio-16by9 {
        .embed-responsive {
          position: relative;
          height: 100%;
        }

        .player-overvideo-items {
          .main-content {
            min-height: auto;
          }
        }
      }

      .close-icon { display: none; }

      &.fullscreen {
        .ratio-16by9 {
          @include media-breakpoint-down(xs) {
            transition: height .4s ease-in-out;
          }
        }

        &:not(.fullscreen--fallback) {
          .ratio-16by9 {
            @include media-breakpoint-down(xs) {
              height: auto !important;
            }
          }
        }
      }
    }
  }

  &.active-side-nav {
    ::v-deep .fandom-player {
      &.player-container {
        &.fullscreen--fallback {
          .ratio-16by9 {
            @include media-breakpoint-down(xs) {
              height: calc((100vw + 0.5rem) * 9 / 16);
            }
          }

          .player-overvideo-items, .embed-responsive {
            width: calc(100% - var(--sidebar-width));
            transition: all .4s ease-in-out;
            left: 0 !important;
            transform: translateX(0);

            @include media-breakpoint-down(xs) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
