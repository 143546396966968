
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-keypoints-with-boxes] {

  .keypoint-box {
    position: relative;
  }

  .keypoint-box__info-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .keypoint-box__info__category {
    color: white;
    text-align: center;
    font-size: 15px;
  }

  .keypoint-box__info__title {
    color: white;
    text-align: center;
  }

  .keypoint-box__info__description, .keypoint-box__info__subtitle {
    color: white;
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
  }

  .keypoint-col.padding-right {
    padding-right: 1px;
    padding-left: 0;
  }

  .keypoint-col.padding-left {
    padding-left: 1px;
    padding-right: 0;
  }

  .keypoint-col.padding-x {
    padding-left: 1px;
    padding-right: 1px;
  }

  @include media-breakpoint-down(xs) {

    .keypoint-col:nth-child(odd) {
      padding-right: 0;
    }

    .keypoint-col:nth-child(even) {
      padding-left: 0;
    }
  }

}

