
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-survey-carousel-component] {

  .survey-modal__background {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background-color: var(--theme-color);
    opacity: .75;
    z-index: 1090;
  }

  .survey-modal {
    padding: 3rem;
    z-index: 1090; 

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .survey-modal__content {
      border-radius: 0.5rem;
      overflow-y: scroll;
      height: 100%;
      width: 100%; 
      background-color: var(--theme-background);
      color: var(--theme-color);

      // > div {
      //   min-height: 100vh;
      // }
    }
  }

  .survey-modal__header a {
    color: var(--theme-color);
    font-size: 2rem;
  }

  .survey__progress {
    height: 10px;

    .progress-bar {
      background: rgb(222,16,45);
      background: linear-gradient(270deg, rgba(222,16,45,1) 0%, rgba(98,50,140,1) 100%);
    }
  } 

  [course-survey-carousel-item-component], .survey__progress {
    width: 600px;
    max-width: 100%;
  }

  @include media-breakpoint-down(xs) {
    
    .survey-modal {
      padding: 1.5rem;
    }
  }

    // for backdrop, because otherwise it will do something weird
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }
}
