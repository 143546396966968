
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-component] {
  min-height: $header-height-sm;
  padding-left: 0;
  padding-right: 0;
  transition: all .5s;

  & > div:first-child,
  & > div:last-child {
    width: 10rem;
  }

  .navbar-brand {
    position: relative;
  }

  .navbar-logo {
    width: 3rem !important;
    height: 20px;
  }

  @include media-breakpoint-up(md) {
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: transparent;
  }

  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

[sidebar-layer] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($dark, .5);
}

[sidebar] {
  z-index: $zindex-sticky + 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 25rem;
  //padding-top: 5rem;
  overflow-y: auto;

  .user-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: #fff;
    stroke: $white;
  }

  // .sidebar-content,
  // .sidebar-footer {
  //   padding-left: 2rem;
  //   padding-right: 2rem;

  // }

  .logo-text {
    font-size: 1.5rem;
    position: static !important;
  }

  .close-icon {
    top: 0;
    right: 0;
  }

  .sidebar-logo {
    width: 6rem !important;
    height: auto;
  }

  .border-bottom {
    border-color: rgba($very-light-pink, 0.15) !important;
  }

  @include media-breakpoint-down(sm) {
    max-width: 17.5rem;
    //padding-top: 1.5rem;

    .sidebar-logo {
      width: 4.375rem !important;
    }

    .nav-item-url,
    .logo-text {
      font-size: .875rem;
    }

    // .sidebar-content,
    // .sidebar-footer {
    //   padding-left: 1.25rem;
    //   padding-right: 1.25rem;
    // }
  }
}

.sidebar-toggler, .close-icon {
  transition: color .25s;
  
  &:hover {
    color: $gray-600;
  }
}

.nav-item-url {
  font-weight: normal;
  text-decoration: none;
  transition: color .25s;

  &.collapsed .fal {
    transform: rotate(180deg);
  }
}

.nav-item {
  // text-transform: uppercase;
  font-size: .875rem;
}

.logo-text {
  font-family: $font-family-headings;
  font-size: $font-size-xs;
  position: absolute;
  font-weight: 100;
  bottom: 0;
  left: 0;
}

.light { 
  color: $black;
  background-color: $white;
  //box-shadow: 0 2px 10px 0 #cccccc;
  border-bottom: 1px solid #cccccc;

  .logo-text,
  .nav-item-url {
    color: $black;
  }
}

.dark {
  color: $white;
  background-color: $gray-1000;
  //box-shadow: 0 2px 10px 0px $black;
  border-bottom: 1px solid $black;
  
  .logo-text,
  .nav-item-url {
    color: $white;
  }
}
