
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.player-controls {
  display: flex;
  height: 100%;
  font-size: 1.3rem;

  button {
    touch-action: manipulation;
    transition: opacity .1s ease-in-out;
    opacity: 0.9;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

    &:hover {
      opacity: 1;
    }
  }

  .left-controls {
    display: flex;
    flex: 1;
    overflow: hidden;
    align-items: flex-end;

    button {
      padding: 0 25px;
    }
  }

  .right-controls {
    display: flex;
    align-items: flex-end;

    button {
      width: 40px;
      padding: 0;
      margin: 0 7px;

      &:last-child {
        margin-right: 0;
      }

      i {
        opacity: 0.9;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
        font-weight: 400 !important;
      }
    }
  }

  .player-button {
    cursor: pointer;
    max-width: 46px;
    padding: 0 0 0 14px;
    border: none;
    background-color: transparent;
    color: $white;

    &:focus {
      outline: none;
    }

    &:first-child {
      padding-left: 0;
    }
  }

  .volume-container {
    flex: 1;

    .volume-slider {
      max-width: 120px !important;
      width: 100% !important;

      @include media-breakpoint-down(sm) {
        display: none !important;
      }

      ::v-deep .vue-slider {
        cursor: pointer;
      }
    }
  }
}
