
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[interaction-vote-component] {

  p {
    font-weight: 700;
    font-family: $font-family-headings;
    color: var(--theme-color);
  }

  .footer {
    color: var(--theme-color);
  }

  .item i {
    font-size: 1.25rem;
  }

  .expired { 
    pointer-events: none;
  }

  .items-container {
    
    &.done > .item > i {
      font-weight: 900;
    }

    .item.active ~ .item > i {
      color: var(--theme-color);
      font-weight: 300;
    }
  }

  .items-container:not(.done):not(.expired) {

    .item {
      color: var(--theme-color);
      font-weight: 300;
    }

    &:hover > .item > i {
      font-weight: 900;
    }

    .item:hover > i {
      font-weight: 900;
    }

    .item:hover ~ .item > i {
      color: var(--theme-color);
      font-weight: 300;
    }
  }

}
