
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-secondary-component] {

  background-color: var(--theme-background);

  .navigation-shadow-wrapper {
    position: relative;
  }

  .navigation-container {
    overflow-x: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
      height: 0;
    }
  }

  .nav-link {
    color: var(--theme-color);
    padding-left: 0;
    padding-right: 0;
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }

    &:hover, &.active {
      color: $red;
    }
  }

  @include media-breakpoint-down(md) {

    /* side shadows */
    .navigation-shadow-wrapper::after,
    .navigation-shadow-wrapper::before {
      z-index: 1;
      content: '';
      height: 100%;
      width: 3rem;
      position: absolute;
      pointer-events: none;
      background: transparent;
    }


    .navigation-shadow-wrapper::before {
      background: linear-gradient(to left, rgba(255, 255, 255, 0), var(--theme-background) 90%);
      left: 0;
    }

    .navigation-shadow-wrapper::after {
      right: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--theme-background) 90%);
    }
    /* end side shadows */
  }
}
