
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-sidebar-component] {
  background: #333333;

  .course-sidebar__header {
    color: $white;

    .course-sidebar__close {
      color: $white;
    }
  }

  .course-sidebar__item-icon {
    i { color: $white; }
  }

  .course-index__item__active {
    a {
      color: $white !important;
      font-weight: bold !important;
    }
  }

  .course-index__item {
    color: $white;
    text-transform: none;
    font-size: 1.125rem;
    font-weight: 300;
    
    .far.fa-chevron-up {
      transition: transform .25s;
    }

    &.collapsed .far.fa-chevron-up {
      transform: rotate(180deg);
    }

    &.secondary {
      font-size: 0.875rem;
    }
  }

  .card {
    border-radius: 0;
    border-bottom: none;
    background-color: transparent;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0 !important;
    background-color: transparent;
    color: $black;
  }

  .card:not(:first-child) {
    border-top: none;
  }

}
