
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.avatar {
  width: 6rem;
  height: 6rem;
}

.name {
  font-family: $font-family-headings;
}

.quote {
  font-size: 1.125rem;
}
