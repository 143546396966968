
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[grid-fixed-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .p-2-5 {
    padding: .75rem !important;

    @include media-breakpoint-down(xs) {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .mx-sm-n2-5 {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
  }
}
