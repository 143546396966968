
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[versus-component] {

  .question-title {
    font-weight: 700;
    font-size: $font-size-base;
    font-family: $font-family-headings;
  }

  .percentage-box {
    .percentage-content {
      font-weight: 500;
    }
  }

  .progress-bar-box {
    height: 4px;
    background-color: transparent;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 0;

    .progress-bar {
      background: var(--theme-color);
      transition: width .5s;
    }
  }

  .answer-text {
    z-index: 1;
  }

  .not-choose {
    opacity: 0.65;
  }

  .choose {
    font-weight: 700;
  }

  .wait-results {
    position: absolute;
    top: -0.5rem;
    bottom: -0.5rem;
    left: 0;
    right: 0;
    background: var(--theme-color);
    z-index: 10;
    color: var(--theme-background);
    i {
      font-size: 4rem;
      z-index: 20;
    }
  }

  .answer {
    font-size: 0.875rem;
    line-height: 1.25;
    cursor: pointer;
    position: relative;
    border: solid 1px #808080;
    overflow: hidden;
    transition: all $animation-time-fast linear;
  }

  .answer:not(.not-answerable):hover {
    background-color: var(--theme-color);
    color: var(--theme-background);
    font-weight: 700;
  }


  @include media-breakpoint-down(xs) {
    .answer {
      font-size: 0.9rem;
    }
  }
}
