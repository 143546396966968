
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@mixin thumb-styles($selector) {
  #{$selector}::-webkit-slider-thumb {
    @content
  }
  #{$selector}::-moz-range-thumb {
    @content
  }
  #{$selector}::-ms-thumb {
    @content
  }
}

[video-popup-component] {
  $progress-icon-size: 2rem;
  $video-progress-bar-marker-size: 2rem;
  $bar-height: .375rem;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1090;
  height: 100vh;

  .video-popup__unmute {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1050;
  }

  .embed-video {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    ::v-deep > div, ::v-deep iframe {
      width: 100%;
      height: 100%;
    }
  }

  .video-popup__prevent-action {
    background-color: transparent;
  }

  .video-popup__progress-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: $bar-height;
    border-radius: .25rem;
  }

  .video-popup__progress {
    width: 101%;
    height: 100%;
    position: absolute;
    top: 0;
    background: $primary;
  }
  
  .video-popup__progress-background {
    width: 100%;
    height: 100%;
    background-color: $white;
    border: 1px solid $gray-200;
  }

  .video-popup__pause i {
    font-size: 5rem;
  }

  .video-popup__close {
    position: absolute;
    top: 0rem;
    right: 1rem;
    color: $white;
    cursor: pointer;
    font-size: 2rem;
  }

  .video-popup__embed-container {
    height: 100vh;
    position: relative;
  }

  .video-popup__progress-bar {
    position: fixed;
    bottom: 35px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 75%;

    .video-popup__progress-bar-input {
      width: 100%;
      appearance: none;
      height: $bar-height * 6;
      outline: none;
      position: absolute;
      top: -$bar-height * 3;
      left: 0;
      background-color: transparent;
      z-index: 1;

      @include thumb-styles("&") {
        appearance: none;
        width: $video-progress-bar-marker-size / 2;
        height: $video-progress-bar-marker-size / 2;
        border-radius: 100%;
        border: none;
        bottom: -$bar-height / 2;
        background: $primary !important;
        cursor: pointer;
        position: relative;
        z-index: 3;
      }
    }

    .video-popup__progress-bar-chapter {
      position: absolute;
      top: unset;
      width: $video-progress-bar-marker-size;
      height: $video-progress-bar-marker-size;
      background-color: $primary;
      border: 1px solid $primary;
      color: $white;
      border-radius: 100%;
      cursor: pointer;
      z-index: 2;

      .far {
        font-size: $video-progress-bar-marker-size * 0.5;
      }

      &.active {
        // background-color: $gray-300;
        background-color: $white;
        border: 0 solid $primary;
        box-shadow: 0 0 0 $video-progress-bar-marker-size * 0.175 inset $primary;
      }

      &.correct {
        background-color: $success;
        border-color: $success;
      }

      &.wrong {
        background-color: $danger;
        border-color: $danger;
      }
    }
  }

  ::v-deep [embed-kaltura-component] > div {
    height: 100vh;
  }

  @include media-breakpoint-down(sm) {

    .video-popup__progress-bar {

      .video-popup__progress-bar-input {
        @include thumb-styles("&") {
          width: $video-progress-bar-marker-size * 0.75 + .115rem;
          height: $video-progress-bar-marker-size * 0.75 + .115rem;
        }
      }

      .video-popup__progress-bar-chapter {
        width: $video-progress-bar-marker-size * 0.75;
        height: $video-progress-bar-marker-size * 0.75;
        .far {
          font-size: $video-progress-bar-marker-size * 0.30;
        }
      }
    }
  }
}
