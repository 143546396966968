
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[faq] {

  .border-bottom-gray-500 {
    border-bottom: solid 1px $gray-500;
  }

  .font-14 {
    font-size: 14px;
  }

  .indent {
    width: 0.25rem;
    position: relative;
    height: 100%;

    &:before {
      content: "";
      width: 1px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $gray-600;
    }
  }

  .bg-gray-100 {
    background-color: $gray-100;
  }

  textarea {
    border: solid 1px $gray-250;
    background-color: $white;
    min-height: 4rem;
    resize: none;

    &.textarea-dark {
      border-color: $gray-100;
      background-color: $black;
      color: $white;
    }
  }
}

