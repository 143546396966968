
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.sticky-top {
  top: $header-height;

  @include media-breakpoint-down(sm) {
    top: $header-height-sm;
  }
}
