
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[grid-carousel-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  position: relative;
  .flickity-page-dots {
    bottom: 20px;

    .dot {
      height: 8px;
      width: 20px;
    }
  }

  .flickity-button.flickity-prev-next-button {
    z-index: 2;
  }

  .flickity-button.flickity-prev-next-button:disabled {
    display: none;
  }

  .flickity-button-icon {
    transform: scale(.65);
  }

  .flickity-carousel-item {
    padding-left: 1rem;
    padding-right: 0rem;

    > div {
      position: relative;
    }
  }

  .stripe-carousel__skin-1 .flickity-carousel-item {
    width: 75%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .stripe-carousel__skin-2 .flickity-carousel-item {
    width: 20%;
  }

  .stripe-carousel__skin-3 {

    .flickity-button.flickity-prev-next-button {
      // display: none;
      background-color: transparent;
      &.previous {
        left: -44px;
      }

      &.next {
        right: -44px;
      }
    }

    .flickity-carousel-item {
      width: 32.05%;
      padding-left: 0;
      margin-right: 1.5rem;
    }
  }

  @include media-breakpoint-down(lg) {

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 25%;
    }
  }

  @include media-breakpoint-down(md) {

    .stripe-carousel__skin-3 {
      
      .flickity-carousel-item {
        width: 40%;
      }
    }
  }

  @include media-breakpoint-down(sm) {

    .flickity-viewport::after,
    .flickity-viewport::before {
      width: 2rem;
    }

    .stripe-carousel__skin-1 .flickity-carousel-item {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      width: 90%;
    }

    .flickity-button.flickity-prev-next-button {
      display: none;
    }

    .stripe-carousel__skin-2 .flickity-carousel-item {
      width: 65%;
    }

    .stripe-carousel__skin-3 .flickity-carousel-item {
      width: 85%;
      padding-left: 1rem;
      margin-right: 0;
    }
  }
}
