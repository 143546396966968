
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

/*TODO: to remove after fixed in variables file*/
$secondary-nav-height: 48px;

.anchor{
  display: block;
  height: calc(#{$header-height-sm} + #{$secondary-nav-height});
  margin-top:  calc(-#{$header-height-sm} - #{$secondary-nav-height});
  visibility: hidden;
}

table {
  font-size: 1rem;
  font-weight: 600;
}
