html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  @include hidpi {
    @include anti-alias-font;
  }
  color: $black !important;
}

.grecaptcha-badge { visibility: hidden; }

@include media-breakpoint-up(xl) {
	.w-limiter {
		max-width: 1920px;
		margin-left: auto;
		margin-right: auto;

    .h-100vh, .h-100vh-header, [style="min-height: 100vh;"] {
      max-height: 1080px;
    }
	}
}

/* Flickity slider override */
.flickity-carousel .flickity-page-dots .dot {
  height: $flickity-dots-height;
  width: 30px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 0;
}

.tile-shadow {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.6);
}

.anchor {
  display: block;
  height: calc(#{$header-height-sm});
  margin-top:  calc(-#{$header-height-sm});
  visibility: hidden !important;
}

/****** SIZING ******/
.ar-3by4 {
  padding-bottom: 127%;
}

.ar-2-75by1 {
  padding-bottom: 36.363636%;

  @include media-breakpoint-down(sm) {
    padding-bottom: 56.25%;
  }
}

h1, .h1 {
  font-weight: 700;
}

h2, .h2 {
  font-weight: 500;
}

h3, .h3 {
  font-weight: 300;
}

h1, h2, .h1, .h2, .display-1 {
  font-family: $font-family-headings;
}

.display-1 {
  font-weight: 700 !important;
}

.inset {
  @include media-breakpoint-up(sm) {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@include media-breakpoint-down(xs) {
  .h2, .h1 {
    font-size: 1.5rem !important;
  }
}

.dark-theme {
  background-color: $black;
  color: $white;
}
