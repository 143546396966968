
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.sidebar-content {
  z-index: 1019;
  height: calc(100vh - #{$header-height});
  top: $header-height;
  background-color: var(--theme-background);
  color: var(--theme-color);
}

.interactions-area {
  flex: 1;
  overflow-y: scroll;

  @include ie {
    height: 100%;
  }
}

@include media-breakpoint-down(md) {
  .interactions-area {
    overflow: visible;
  }

  .sidebar-content {
    background: none;
    position: relative;
    height: auto;
    top: 0;
  }
}

