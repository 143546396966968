
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keypoints-v3-component] {
  
  .py-large {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include media-breakpoint-down(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .divider {
    width: 2px;
    background-color: $red;
  }

  td {
    border: none;
  }

  .number-cell {
    vertical-align: baseline;
  }

  .description-cell {
    padding-top: 1rem;
    width: 100%;
    max-width: 46.875rem;
  }

  .dot {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    right: -10px;
    background-color: var(--background-color);
    position: absolute;
    top: 1rem;
    border: 2px solid $red;
  }


  .number {
    font-size: 1.25rem;
    padding-right: 3rem;
    line-height: 2.25rem;
    font-weight: 700;
    white-space: nowrap;
  }

  .description {
    font-size: 14px;
  }

  .disclaimer {
    font-size: $font-size-xs;
  }
}
