
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.deco-none {
  color: inherit !important; 
}

.live-event-sidebar {

  z-index: 1019;
  position: relative;
  color: var(--theme-color);
  border-bottom: 1px solid $gray-300;
  background-color: var(--theme-background);

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: $header-height;
    height: calc(100vh - #{$header-height});
    border-bottom: 0;
  }
}

.list-group-item-action {
  width: auto !important;
  background-color: var(--theme-background);
  color: var(--theme-color);

  &.active {
    color: inherit;
    font-weight: 500;

    &:after {
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      position: absolute;
      border-bottom: 3px solid var(--theme-color) !important;
    }
  }
}

[data-toggle="collapse"] {
  &:not(.collapsed) > .open-icon,
  &.collapsed > .close-icon {
    display: none;
  }
}

.interactions-area {
  max-height: 100%;
  
  @include media-breakpoint-up(lg) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  @include ie {
    height: 100%;
  }
}
