
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-video-control-bar-component] {
  ::v-deep i {
    font-size: 1.5rem;
  }

  .video-controls {
    height: $video-controlbar-height;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .fullscreen-controls i {
    position: relative;
    top: 2px;
  }

  .quality-controls.active .qualities-list {
    display: block;
  }

  .qualities-list {
    display: none;
    right: 0;
    width: 7rem;
    position: absolute;
    bottom: 100%;
    background-color: rgba(0, 0, 0, .75);

    .dot-container {
      width: 1rem;
      i { font-size: 1rem !important; }
    }
  }
}
