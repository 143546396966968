
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.person {
  color: $dark;
  background-color: #f9f9f9;

  .dark & {
    color: $light;
    background-color: #1a1a1a;
  }
  
  &.has-bio {
    @include hover() {
      .arrow {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.arrow {
  font-size: 1.5rem;
  transform: translate3d(-1.5rem, 0, 0);
  transition: transform .5s;
  width: 3rem;
}

.role {
  font-size: 1rem;
}

.description {
  min-width: 50%;
}

.photo {
  width: 3.5rem;
  min-width: 3.5rem;

  @include media-breakpoint-up(md) {
    width: 5rem;
    min-width: 5rem;
  }
}
