$beige: #D0C6BB;
$pale-aqua: #C9E1DB;
$light-sage: #CAE0BE;
$light-grey: #F2F2F2;
$greyish-brown: #505050;
$very-light-pink: #E5E5E5;
$white: #FFFFFF;
$black: #000000;
$gray-f0f0f0: #f0f0f0;
$gray-ccc: #cccccc;
$gray-200: #F2F2F2;
$gray-225: #f5f5f5;
$gray-250: #d7d7d7;
$gray-300: #e6e6e6;
$gray-400: #a6a6a6;
$gray-500: #979797;
$gray-600: #909090;
$gray-650: #6e6e6e;
$gray-700: #5f5f5f;
$gray-800: #505050;
$gray-900: #303030;
$gray-1000: #2b2b2b;
$red: rgb(245, 5, 55);
$dark: #333333;

// Bootstrap 
$primary: $red;
$secondary: $beige;
$link-hover-decoration: none;

$danger: #bb0a30;
$success: #009900;

$audi-colors: (
  "red": $red,
  "white": $white,
  "black": $black,
  "beige": $beige,
  "pale-aqua": $pale-aqua,
  "light-sage": $light-sage,
  "light-grey": $light-grey,
  "greyish-brown": $greyish-brown,
  "very-light-pink": $very-light-pink,
  "gray-ccc": $gray-ccc,
  "gray-200": $gray-200,
  "gray-225": $gray-225,
  "gray-250": $gray-250,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-650": $gray-650,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "gray-1000": $gray-1000,
  "gray-f0f0f0": $gray-f0f0f0,
  "dark": $dark
);

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 916px,
  lg: 1180px,
  xl: 1360px
);

$container-max-widths: (
  sm: 752px,
  md: 880px,
  lg: 1136px, 
  xl: 1334px
);

$zindex-youtube-popup: 1090;
$zindex-breadcrumb: 1010;

$font-size-base: 1.125rem;
$font-size-sm: .875rem;
$flickity-dots-height: 3px;

$display1-size: 3rem;
$display2-size: 2.25rem;
$h1-font-size: 2rem;
$h2-font-size: 2rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.125rem;
$h5-font-size: .875rem;

$headings-margin-bottom: 1rem;

$display1-size-sm: $display1-size * .625;
$display2-size-sm: $h1-font-size;
$h1-font-size-sm: $h1-font-size;
$h2-font-size-sm: $h2-font-size;

// Change the default font weight of bootstrap
$font-weight-base: 300;
$font-weight-demi: 600;
$headings-font-weight: 700;
$display2-weight: 800;
$font-weight-bold: 700;
$font-weight-bolder: $font-weight-bold;

// Change the default font family of bootstrap
$font-family-base: "Audi", sans-serif;
$font-family-headings: "Audi Extended", sans-serif;

// Buttons
$btn-padding-y: 1rem;
$btn-padding-x: 2rem;
$btn-font-size: .875rem;
$btn-font-weight: 500;
$btn-border-radius: 0px;

@import "~src/stylesheets/fandom_variables";
$isotope-grid-gap-row: 1rem;
$isotope-grid-gap-column: 2rem;
$colors: map-merge($colors, $audi-colors);
$theme-colors: map-merge($theme-colors, $audi-colors);

$header-height: 63px;
$header-height-sm: $header-height;
$secondary-nav-height: $header-height;

// font sizes

$font-size-lg: 3rem;
$font-size-md: 1.5rem;
$font-size-sm: 1rem;
$font-size-xs: .8125rem;

$padding-large: 6.5rem;
$padding-sm-large: 3.25rem;
