
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-trivia-result-component] {

	.counter-box {
		width: 4rem;
    height: 4rem;
    border: 1px solid $gray-800;
    line-height: 4rem;
    font-size: 2.5rem;
	}
}
