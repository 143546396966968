
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


[stripe-one-col-media-component] {
	position: relative;
  color: $white;

  .play-icon {
    stroke: $white;
  }

  @include media-breakpoint-up(sm){
    .inset .mh-100vh-header {
      min-height: calc(100vh - #{$header-height-sm} - 10rem);
    }
  }

  .info-logo {
    width: 12.5rem;
    height: auto;
    right: 3rem;
    bottom: 2rem;
  }
	
	.next-container {
		font-size: 1.5rem;
		position: absolute;
    bottom: 0rem;
    left: 0;
	}

  .category {
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Audi Extended';

    &.category_danger {
      border-radius: 5px;
      padding: 0.5rem 1.25rem;
      background-color: $red;
    }
  }

	.icon {
    width: 25rem;
    max-width: 60%;

    @include ie {
      height: 100%;
    }
  }

  .description {
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }

  .countdown-container {
    height: 8rem;

    h3 {
      font-family: $font-family-headings;
    }

    .countdown-description {
      font-size: 1rem;
    }
  }

  .content-container {
    //@include media-breakpoint-up(md) {
      padding-top: 4.5rem;
      padding-bottom: 4.5rem;
    //}
    min-height: 600px;

    @include media-breakpoint-up(xl) {
      max-height: 1080px !important;
    }

    @include media-breakpoint-down(sm) {
      height: auto !important;
    }
  }
}
