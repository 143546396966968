
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-filters-component] {
  background-color: var(--theme-background);
  color: var(--theme-color);

  .modal .btn.active {
    background-color: $black;
    color: $white;
  }

  .filter-icon {
    width: 1.25rem;
    height: 1.25rem;
    stroke: var(--theme-color);
    transition: all .25s;
  }

  .modal-header  {
    right: 0;
    z-index: 1;
  }

  .category-title {
    font-family: $font-family-headings;
    font-size: 1.25rem;
  }

  button.big {
    font-size: 18px;
  }

  .btn:hover .filter-icon {
    stroke: var(--theme-background);
  }

  .title-section {
    @include media-breakpoint-down(sm) {
      text-align: center;

      h2 {
        font-size: 1rem;
      }

      .btn {
        width: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modal-lg {
      max-width: 700px;
    }
  }

  @include media-breakpoint-up(lg) {
    .modal-lg {
      max-width: 950px;
    }
  }
}
