
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[table-simple-component] { 
  
  .anchor{
    display: block;
    height: calc(#{$header-height-sm} + #{$secondary-nav-height});
    margin-top:  calc(-#{$header-height-sm} - #{$secondary-nav-height});
    visibility: hidden;
  }

  .cell {
    vertical-align: middle;

    &.date {
      width: 8rem;
    }
  }

  .custom-select {
    height: 2rem;
    border-radius: 0;
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .attachment-column {
    width: 4rem;
  }

}
