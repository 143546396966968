
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[cookies-banner-component] {
  background-color: rgba($white, .9);
  z-index: 1090;
  position: fixed;
  bottom: 0%;

  p {
    font-size: .75rem;
  }
}
