
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[live-modal-component] {

  .modal__background {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background-color: var(--theme-color);
    opacity: .75;
    z-index: 1090;
  }

  .live-modal {
    z-index: 1090; 

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    .modal__content {
      overflow-y: scroll;
      width: 65%;
      max-height: calc(100% - 3rem);
      background-color: var(--theme-background);
      color: var(--theme-color);

      @include media-breakpoint-down(sm) {
        width: calc(100% - 3rem);
      }
    }
  }

  .modal__header {
    top: 0;
  }

  .modal__header a {
    color: var(--theme-color);
    font-size: 2rem;
    line-height: 0;

    & > i {
      line-height: 1.3rem;
    }
  }

  // for backdrop, because otherwise it will do something weird
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }
}
