
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-blank-component] {
  background-color: var(--theme-background);
  position: relative;

  &.secondary-navbar-present .breadcrumb-container {
    top: $secondary-nav-height;
  }

  &.secondary-navbar-present {
    .first .mh-100vh-header {
      min-height: calc(100vh - #{$header-height} - #{$secondary-nav-height});
    }

    .first .h-100vh-header {
      height: calc(100vh - #{$header-height} - #{$secondary-nav-height});
    }
  }

}
