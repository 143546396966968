
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-event-heading-component] {
  ::v-deep [stripe-button-component] {
    z-index: $zindex-sticky - 1;
  }
}
