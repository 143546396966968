
    @import "tenants/tenants-base/audi_events/javascript/src/stylesheets/audi_events_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-event-component] {

  color: var(--theme-color);
  background-color: var(--theme-background);

  .live-event-interactive-sidebar {
    width: 26.5rem;
  }

  // styles to apply to all modals in this place
  // TODO: better put it somewhere else?
  [modal] {
    .live-event-modal__background {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      pointer-events: none;
      z-index: $zindex-modal-backdrop;
      background-color: var(--theme-color);
      opacity: .75;
    }

    .live-event-modal {
      top: 0;
      left: 0;
      position: fixed;
      z-index: $zindex-modal;
    }
  }
  
  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: .75;}
  }

  @keyframes fadeOut {
    0% {opacity: .75;}
    100% {opacity: 0;}
  }
}
